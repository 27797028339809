var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "#525252",
      "flat": "",
      "height": "30px",
      "width": "122px"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('v-icon', {
    attrs: {
      "size": "16"
    }
  }, [_vm._v("$shop")]), _c('div', {
    staticClass: "font-18px white--text pl-2"
  }, [_vm._v(" 店舗一覧 ")])], 1)])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('TableSearch', {
    attrs: {
      "search": _vm.search
    },
    on: {
      "update:search": [function ($event) {
        _vm.search = $event;
      }, _vm.getDataFromApi]
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "to": {
        name: 'ShopsCreate'
      },
      "color": "primary",
      "text": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" 新規登録 ")], 1)], 1)], 1)], 1), _c('v-card-text', {
    staticClass: "px-0"
  }, [_c('Table', {
    attrs: {
      "headers": _vm.headers,
      "loading": _vm.loading,
      "items": _vm.getShops,
      "total-records": _vm.pagination ? _vm.pagination.records_total : 0,
      "number-of-pages": _vm.pagination ? _vm.pagination.total_pages : 0
    },
    on: {
      "click:row": function clickRow($event) {
        return _vm.$router.push({
          name: 'ShopsEdit',
          params: {
            shop_id: $event.id
          }
        });
      },
      "update:options": _vm.updateTable
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "column-title"
        }, [_vm._v(_vm._s(item.id))])];
      }
    }, {
      key: "item.title",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex flex-column align-center"
        }, [_c('div', {
          staticClass: "column-title"
        }, [_vm._v(_vm._s(item.store_display_name))]), _c('div', {
          staticClass: "column-subtitle"
        }, [_vm._v(" " + _vm._s(_vm.getPrefectureData(item.prefectures)) + " ")])])];
      }
    }, {
      key: "item.company",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "column-title"
        }, [_vm._v(_vm._s(_vm.getCompany(item.company_type)))])];
      }
    }, {
      key: "item.telephone",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "column-title"
        }, [_vm._v(_vm._s(item.phone_number))])];
      }
    }, {
      key: "item.number_of_employees",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "column-title"
        }, [_vm._v(" " + _vm._s(_vm.getEmployee(item.number_of_employees)) + " ")])];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "column-title"
        }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.created_at, "YYYY-MM-DD")) + " ")])];
      }
    }, {
      key: "item.counts",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "edit d-flex justify-space-around align-center"
        }, [_c('v-icon', {
          attrs: {
            "size": "16px"
          }
        }, [_vm._v("$person")]), _c('div', {
          staticClass: "column-title"
        }, [_vm._v(" " + _vm._s(_vm.getNoOfShopUsers(item.user_shop)) + " ")]), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }