<template>
  <v-col cols="12">
    <v-card>
      <v-card-title>
        <v-row align="center">
          <v-col cols="auto">
            <v-card color="#525252" flat height="30px" width="122px">
              <div class="d-flex justify-center align-center">
                <v-icon size="16">$shop</v-icon>
                <div class="font-18px white--text pl-2">
                  店舗一覧
                </div>
              </div>
            </v-card>
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="3">
            <TableSearch
              :search.sync="search"
              @update:search="getDataFromApi"
            ></TableSearch>
          </v-col>
          <v-col cols="auto">
            <v-btn
              :to="{ name: 'ShopsCreate' }"
              color="primary"
              text
              class="text-capitalize font-weight-regular"
            >
              <v-icon left>mdi-plus</v-icon>
              新規登録
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="px-0">
        <Table
          :headers="headers"
          :loading="loading"
          :items="getShops"
          :total-records="pagination ? pagination.records_total : 0"
          :number-of-pages="pagination ? pagination.total_pages : 0"
          @click:row="$router.push({ name: 'ShopsEdit', params: { shop_id: $event.id } })"
          @update:options="updateTable"
        >
          <template v-slot:item.id="{ item }">
            <div class="column-title">{{ item.id }}</div>
          </template>
          <template v-slot:item.title="{ item }">
            <div class="d-flex flex-column align-center">
              <div class="column-title">{{ item.store_display_name }}</div>
              <div class="column-subtitle">
                {{ getPrefectureData(item.prefectures) }}
              </div>
            </div>
          </template>
          <template v-slot:item.company="{ item }">
            <div class="column-title">{{ getCompany(item.company_type) }}</div>
          </template>
          <template v-slot:item.telephone="{ item }">
            <div class="column-title">{{ item.phone_number }}</div>
          </template>
          <template v-slot:item.number_of_employees="{ item }">
            <div class="column-title">
              {{ getEmployee(item.number_of_employees) }}
            </div>
          </template>
          <template v-slot:item.created_at="{ item }">
            <div class="column-title">
              {{ item.created_at | formatDate("YYYY-MM-DD") }}
            </div>
          </template>
          <template v-slot:item.counts="{ item }">
            <div class="edit d-flex justify-space-around align-center">
              <v-icon size="16px">$person</v-icon>
              <div class="column-title">
                {{ getNoOfShopUsers(item.user_shop) }}
              </div>
              <v-icon>mdi-chevron-right</v-icon>
            </div>
          </template>
        </Table>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import Table from "@/components/admin/partials/Table/Table";
import TableSearch from "@/components/admin/partials/Search/TableSearch";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";

export default {
  components: {
    Table,
    TableSearch
  },
  data() {
    return {
      search: "",
      options: {},
      loading: false,
      initialLoad: true,
      headers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          class: "px-2"
        },
        {
          text: "  店舗名",
          value: "store_name",
          align: "center",
          class: "mr-2",
          width: "150",
          sortable: false
        },
        {
          text: "会社形態",
          value: "company",
          align: "center",
          width: "300",
          sortable: false
        },
        {
          text: "電話番号",
          value: "telephone",
          align: "center",
          width: "300",
          sortable: false
        },
        {
          text: "従業員数",
          value: "number_of_employees",
          align: "center",
          width: "300",
          sortable: false
        },
        {
          text: "登録日",
          value: "created_at",
          align: "center",
          width: "300"
        },
        {
          text: "",
          value: "counts",
          align: "right",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getShops"]),
    pagination() {
      return this.$store.getters.ShopPagination;
    },
    masterData() {
      return this.$store.getters.getMasterData;
    }
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    updateTable(e) {
      if (!this.initialLoad) this.getDataFromApi(e);
    },
    async getDataFromApi(e) {
      let params = {};

      if (e?.sortBy !== undefined && e?.sortDesc !== undefined) {
        params.orderDesc = e.sortDesc[0] ? 0 : 1;
        params.orderBy = e.sortBy[0];
      } else {
        params.orderBy = "id";
        params.orderDesc = 0;
      }

      if (this.search) {
        params.search = this.search;
      }

      if (e?.page !== undefined && e?.itemsPerPage !== undefined) {
        params.page = e.page;
        params.paginate = e.itemsPerPage;
      } else {
        params.page = 1;
        params.paginate = 50;
      }

      this.loading = true;
      await this.$store.dispatch("SHOP_GET_ALL", params).then(() => {
        this.loading = false;
        this.initialLoad = false;
      });
    },
    getPrefectureData(prefecture_id) {
      return this.$store.getters.getPrefectures.find(i => i.id == prefecture_id)?.name || "";
    },
    getCompany(company_id) {
      return this.masterData.company_type.find(i => i.id == company_id)?.value || "";
    },
    getEmployee(employee_id) {
     return this.masterData.number_of_employees.find(i => i.id == employee_id)?.value || "";
    },
    getNoOfShopUsers(ShopUsers) {
      return ShopUsers.filter(elem => elem.user_role === "M" || elem.user_role === "P").length || 0;
    }
  }
};
</script>
<style lang="scss" src="./global.scss" scoped></style>